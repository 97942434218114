import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', {
    staticClass: "container",
    attrs: {
      "id": "calendar"
    }
  }, [_c('div', {
    staticClass: "padding"
  }, [_vm._l(_vm.translator_kontakterminart, function (tk, idk) {
    return idk != 'andere' ? _c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(tk.text) + ": "), _c('i', {
      staticClass: "icon-circle-fill",
      style: {
        color: tk.color
      }
    }), _c('div', {
      staticClass: "spacer _horizontal _small"
    })]) : _vm._e();
  }), _c('div', {
    staticClass: "spacer _horizontal"
  }, [_vm._v("|")]), _c('span', [_c('toggle-button', {
    attrs: {
      "color": {
        checked: '#67ab7b',
        unchecked: '#67ab7b',
        disabled: '#CCCCCC'
      },
      "height": 20,
      "width": 84,
      "sync": true,
      "labels": {
        checked: 'alle',
        unchecked: 'mit Faktor'
      }
    },
    model: {
      value: _vm.isShowingOnlySvWithFactor,
      callback: function callback($$v) {
        _vm.isShowingOnlySvWithFactor = $$v;
      },
      expression: "isShowingOnlySvWithFactor"
    }
  })], 1)], 2), _c('div', {
    staticClass: "daysofftable condensed"
  }, [_c('table', {
    staticClass: "table daysofftable"
  }, [_c('thead', [_c('tr', [_c('v-table-head', {
    attrs: {
      "label": "SV",
      "sortvalue": "lastname",
      "sortby": _vm.sortTableBy,
      "reverse": _vm.isListReversed
    },
    on: {
      "change": function change($event) {
        _vm.sortTableBy = $event[0];
        _vm.isListReversed = !_vm.isListReversed;
      }
    }
  }), _c('v-table-head', {
    attrs: {
      "label": "A.",
      "sortvalue": "akts",
      "sortby": _vm.sortTableBy,
      "reverse": _vm.isListReversed
    },
    on: {
      "change": function change($event) {
        _vm.sortTableBy = $event[0];
        _vm.isListReversed = !_vm.isListReversed;
      }
    }
  }), _c('v-table-head', {
    attrs: {
      "label": "F.",
      "sortvalue": "factor",
      "sortby": _vm.sortTableBy,
      "reverse": _vm.isListReversed
    },
    on: {
      "change": function change($event) {
        _vm.sortTableBy = $event[0];
        _vm.isListReversed = !_vm.isListReversed;
      }
    }
  }), _vm._l(_vm.displayDays, function (headDay) {
    return _c('th', [_c('small', [_vm._v(_vm._s(_vm.formatDateForDayDiff(headDay).day))])]);
  })], 2)]), _c('tbody', _vm._l(_vm.filteredSortedAllDaysOffBySv, function (sv, index) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(sv.name))]), _c('td', {
      staticClass: "tac"
    }, [_vm._v(_vm._s(sv.akts))]), _c('td', {
      staticClass: "tac"
    }, [_vm._v(_vm._s(sv.factor))]), _vm._l(_vm.displayDays, function (day) {
      return _c('td', {
        staticClass: "tac",
        class: {
          weekend: _vm.formatDateForDayDiff(day).weekend
        }
      }, [_vm._v(_vm._s(sv.daysoff.von)), _vm._l(sv.daysoff, function (svday) {
        return _c('span', [svday.von - day - 1 <= 0 && svday.bis - day > 0 ? _c('span', [_c('div', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.top-center",
            value: svday.art + "    " + svday.specs,
            expression: "svday.art + \"    \" + svday.specs",
            modifiers: {
              "top-center": true
            }
          }],
          staticClass: "circle-filler"
        }, [_c('i', {
          staticClass: "icon-circle-fill",
          style: {
            color: svday.artcolor
          }
        })])]) : _vm._e()]);
      })], 2);
    })], 2);
  }), 0)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };